import React from "react"

import Text from "../Text"
import Social from "../Social"

import { FaRegEnvelope } from "react-icons/fa"

import profile from "../../images/profile.jpg"

import * as S from "./styles"

export default function UserCard() {
  return (
    <S.UserCard>
      <S.Body>
        <S.Profile src={profile} alt="" />
        <Text size="sm" mb="15" align="center">
          Bruno Henrique Saibert
        </Text>
        <Text size="xs" color="cloud" mb="30" align="center">
          Desenvolvedor Full-stack NodeJS, ReactJS e React Native.
        </Text>

        <Social names={false} />
      </S.Body>
      <S.Footer>
        <a href="mailto:brunosaibert@gmail.com.br">
          <FaRegEnvelope /> brunosaibert@gmail.com.br
        </a>
      </S.Footer>
    </S.UserCard>
  )
}
