import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import { Container, Row, Col } from "../components/Grid"
import Text from "../components/Text"
import UserCard from "../components/UserCard"

const ContatoPage = () => (
  <Layout>
    <SEO title="Contato" />

    <Container full>
      <Row alignItems="center">
        <Col size="12" md="4">
          <UserCard />
        </Col>
        <Col size="12" md="8">
          <Text as="h2" mt="100" mb="30">
            Entre em contato comigo!
          </Text>
          <Text size="sm" line="15" mb="100">
            Se você tem alguma dúvida ou gostaria de mais detalhes sobre algo,
            entre em contato através do e-mail ou se preferir utilize uma das
            minhas redes sociais.
          </Text>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ContatoPage
